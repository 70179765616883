import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://98e2bf181d1946ce8785b0cf93b074ae@o999875.ingest.sentry.io/6130839',
  integrations: [new Integrations.BrowserTracing()],
  environment: 'staging',
  // for finer control
  tracesSampleRate: 0,
  ignoreErrors: ['Non-Error promise rejection captured'] // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
